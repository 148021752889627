import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import usdaLogo1x from '../images/usda-organic-seal@1x.png';
import usdaLogo2x from '../images/usda-organic-seal@2x.png';

const useOrganicPulloutFrontmatter = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { component: { eq: "organicPullout" } }) {
        html
        frontmatter {
          component
          heading
        }
      }
    }
  `);
  return data;
};

const OrganicPullout = () => {
  const data = useOrganicPulloutFrontmatter();

  return (
    <div className='organic-pullout'>
      <h3>{data.markdownRemark.frontmatter.heading}</h3>
      <img src={usdaLogo1x} srcSet={`${usdaLogo2x} 2x`} alt='USDA organic seal' />
      <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
    </div>
  );
};

export default OrganicPullout;
