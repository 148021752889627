import PropTypes from 'prop-types';
import React from 'react';
import { navigate } from 'gatsby';

const Hero = ({ headline, description, buttonLink, buttonText }) => {
  const doClick = (e) => {
    e.preventDefault();
    navigate(buttonLink);
  };
  return (
    <div className='hero'>
      <div className='hero--mobile-image hide-for-medium'></div>
      <div className='hero--inner'>
        <h1>{headline}</h1>
        <p>{description}</p>
        <p>
          <a className='button reverse no-span' href={buttonLink} onClick={doClick}>
            {buttonText}
          </a>
        </p>
      </div>
    </div>
  );
};

Hero.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
};

export default Hero;
