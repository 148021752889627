import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import _ from 'lodash';
import slugify from 'slugify';

const FeaturedProducts = ({ title, categories }) => {
  const buildCategoryName = (name) => {
    // find where the plant name ends
    const suffixIndex = name.indexOf(' Plug Trays');
    const plantName = name.slice(0, suffixIndex);
    return (
      <p>
        {plantName}
        <br />
        Plug Trays
      </p>
    );
  };
  const buildCategoryThumbnail = (category, index) => {
    return (
      <div key={index} className='cell small-12 medium-6 large-3'>
        <Link to={`/${slugify(_.toLower(category.tags[0]))}`}>
          <GatsbyImage
            image={category.imgPath.childImageSharp.gatsbyImageData}
            className='product-thumb--image__featured'
            alt={category.name}
          />
          <div className='product-thumb--title__featured'>{buildCategoryName(category.name)}</div>
        </Link>
      </div>
    );
  };
  return (
    <div className='grid-x grid-margin-x'>
      <h2 className='cell small-12'>{title}</h2>
      {_.map(categories, buildCategoryThumbnail)}
    </div>
  );
};

FeaturedProducts.defaultProps = {
  title: undefined,
  categores: undefined,
};

FeaturedProducts.propTypes = {
  categories: PropTypes.array,
  title: PropTypes.string,
};

FeaturedProducts.displayName = 'FeaturedProducts';

export default FeaturedProducts;
